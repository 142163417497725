import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const LOAD_FERTILIZER_LIST_SUCCESS = 'LOAD_FERTILIZER_LIST_SUCCESS';
export const LOAD_FERTILIZER_LIST_FAILED = 'LOAD_FERTILIZER_LIST_FAILED';

export const CREATE_FERTILIZER_SUCCESS = 'CREATE_FERTILIZER_SUCCESS';
export const CREATE_FERTILIZER_FAILED = 'CREATE_FERTILIZER_FAILED';

export const UPDATE_FERILIZER_SUCCESS = 'UPDATE_FERILIZER_SUCCESS';
export const UPDATE_FERILIZER_FAILED = 'UPDATE_FERILIZER_FAILED';

// Reducer

const initialState = {
  all: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FERTILIZER_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_FERTILIZER_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CREATE_FERTILIZER_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
      };
    case CREATE_FERTILIZER_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case UPDATE_FERILIZER_SUCCESS:
      return {
        ...state,
        ...action.data,
        all: state.all.map((item) => {
          if (item._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return item;
        }),
      };

    case UPDATE_FERILIZER_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

// Action Creators

export const loadFertilizerListSuccess = (data) => {
  return {
    type: LOAD_FERTILIZER_LIST_SUCCESS,
    data,
  };
};

export const loadFertilizerListFailed = (error) => {
  return {
    type: LOAD_FERTILIZER_LIST_FAILED,
    error,
  };
};

export const createFertilizerSuccess = (data) => {
  notification['success']({
    message: i18next.t('fertilizer:control.notification.create_success_message.message'),
    description: i18next.t('fertilizer:control.notification.create_success_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_FERTILIZER_SUCCESS,
    data,
  };
};

export const createFertilizerFailed = (data) => {
  notification['error']({
    message: i18next.t('fertilizer:control.notification.create_failed_message.message'),
    description: i18next.t('fertilizer:control.notification.create_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_FERTILIZER_FAILED,
    data,
  };
};

export const updateFertilizerSuccess = (data) => {
  notification['success']({
    message: i18next.t('fertilizer:control.notification.update_success_message.message'),
    description: i18next.t('fertilizer:control.notification.update_success_message.description'),
    className: 'sub2',
  });
  return {
    type: UPDATE_FERILIZER_SUCCESS,
    data,
  };
};

export const updateFertilizerFailed = (data) => {
  notification['error']({
    message: i18next.t('fertilizer:control.notification.update_failed_message.message'),
    description: i18next.t('fertilizer:control.notification.update_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: UPDATE_FERILIZER_FAILED,
    data,
  };
};

export const loadFertilizerList = () => {
  return (dispatch) => {
    axios
      .get('/fertilizers', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadFertilizerListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadFertilizerListFailed(error));
      });
  };
};

export const createFertilizer = (data) => {
  return (dispatch) => {
    axios
      .post(`/fertilizers`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(createFertilizerSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createFertilizerFailed(error));
      });
  };
};

export const updateFertilizer = (data, fertilizerId) => {
  return (dispatch) => {
    axios
      .patch(`/fertilizers/${fertilizerId}`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(updateFertilizerSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateFertilizerFailed(error));
      });
  };
};
