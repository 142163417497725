import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';

import './i18n';
import './index.css';
import initStore from './store';
import theme from './theme.json';
import App from './App';
import ErrorFallback from './UnhandledErrorFallback';

import registerServiceWorker from './registerServiceWorker';
import refParser from 'json-schema-ref-parser';

async function renderCore() {
  const resolvedTheme = await refParser.dereference(theme);
  const app = (
    <Provider store={initStore()}>
      <BrowserRouter getUserConfirmation={() => {}}>
        <ThemeProvider theme={resolvedTheme}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <App />
          </ErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );

  ReactDOM.render(app, document.getElementById('root'));
  registerServiceWorker();
}

renderCore();
