import styled from 'styled-components';
import profile from '../../../../assets/icon/large_profile.svg';

const ProfileImage = styled.img`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.profile_gray};
  background-image: url(${profile});
  object-fit: cover;

  :hover {
    background-color: ${(props) => props.theme.darker_gray};
  }
`;

ProfileImage.defaultProps = {
  size: 40,
};

export default ProfileImage;
