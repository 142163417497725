import React from 'react';
import compose from 'recompose/compose';
import styled, { withTheme } from 'styled-components';

const StyledSpinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }

  position: relative;
  display: inline-block;
  width: 5em;
  height: 5em;
  /* margin: 0 0.5em; */
  font-size: ${(props) => props.height || '6px'};
  text-indent: 999em;
  overflow: hidden;
  animation: spin 1s infinite steps(12);

  > .set1:before,
  > .set1:after,
  > .set3:before,
  > .set3:after,
  > .set2:before,
  > .set2:after {
    content: '';
    position: absolute;
    top: 0;
    /* (container width - part width)/2  */
    left: 2.4em;
    width: 0.2em;
    height: 1.2em;
    border-radius: 0.02em;
    background: ${(props) => props.theme[props.color] || props.theme.white};
    /*container height - part height*/
    /* box-shadow: 0 3.8em ${(props) => props.theme[props.color] || props.theme.white} 0.9; */
    /* container height / 2 */
    transform-origin: 50% 2.5em;
    opacity: 0.9;
  }
  > .set1:before {
    transform: rotate(-0deg);
    background: ${(props) => props.theme[props.color] || props.theme.white};
    opacity: 0.9;
  }

  > .set1:after {
    transform: rotate(-30deg);
    background: ${(props) => props.theme[props.color] || props.theme.white};
    opacity: 0.8;
  }

  > .set2:before {
    transform: rotate(-60deg);
    background: ${(props) => props.theme[props.color] || props.theme.white};
    opacity: 0.6;
  }

  > .set2:after {
    transform: rotate(-90deg);
    background: ${(props) => props.theme[props.color] || props.theme.white};
    opacity: 0.4;
  }

  > .set3:before {
    transform: rotate(-120deg);
    background: ${(props) => props.theme[props.color] || props.theme.white};
    opacity: 0.3;
  }

  > .set3:after {
    transform: rotate(-150deg);
    background: ${(props) => props.theme[props.color] || props.theme.white};
    opacity: 0.1;
  }
`;

const SpinnerComponent = (props) => (
  <StyledSpinner color={props.color} height={props.height}>
    {/* <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth={lineThickMapper[props.size]} /> */}
    <div className='set1' />
    <div className='set2' />
    <div className='set3' />
  </StyledSpinner>
);

export default compose(withTheme)(SpinnerComponent);
