import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';
import Collator from '../collator';

// Actions
export const LOAD_SITE_LIST_SUCCESS = 'LOAD_SITE_LIST_SUCCESS';
export const LOAD_SITE_LIST_FAILED = 'LOAD_SITE_LIST_FAILED';

export const LOAD_SITE = 'LOAD_SITE';
export const LOAD_SITE_SUCCESS = 'LOAD_SITE_SUCCESS';
export const LOAD_SITE_FAILED = 'LOAD_SITE_FAILED';

export const UPDATE_SITE_PROPERTY = 'UPDATE_SITE_PROPERTY';
export const CLOSE_SITE_FORM = 'CLOSE_SITE_FORM';

export const NEW_SITE = 'NEW_SITE';
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS';
export const CREATE_SITE_FAILED = 'CREATE_SITE_FAILED';

export const EDIT_SITE = 'EDIT_SITE';
export const SAVE_SITE_SUCCESS = 'SAVE_SITE_SUCCCESS';
export const SAVE_SITE_FAILED = 'SAVE_SITE_FAILED';

export const CLEAR_SELECTED_SITE = 'CLEAR_SELECTED_SITE';
export const CLEAR_SITE_LIST = 'CLEAR_SITE_LIST';

export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILED = 'DELETE_SITE_FAILED';

const initialState = {
  all: [],
  selected: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SITE_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_SITE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_SITE_SUCCESS:
      return {
        ...state,
        selected: action.data,
      };
    case LOAD_SITE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_SITE_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case CLOSE_SITE_FORM:
      return {
        ...state,
        form: undefined,
      };
    case NEW_SITE:
      return {
        ...state,
        form: {
          isNew: true,
        },
      };
    case CREATE_SITE_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        form: undefined,
      };
    case CREATE_SITE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_SITE:
      return {
        ...state,
        form: {
          ...state.selected,
        },
      };
    case SAVE_SITE_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        selected: {
          ...state.selected,
          ...action.data,
        },
        form: undefined,
      };
    case SAVE_SITE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_SELECTED_SITE:
      return {
        ...state,
        selected: null,
      };
    case CLEAR_SITE_LIST:
      return {
        ...state,
        all: [],
      };
    case DELETE_SITE_SUCCESS:
      return {
        ...state,
        all: state.all.filter((state) => state._id !== action.data._id),
      };
    case DELETE_SITE_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export const loadSiteListSuccess = (data) => {
  return {
    type: LOAD_SITE_LIST_SUCCESS,
    data,
  };
};

export const loadSiteListFailed = (error) => {
  return {
    type: LOAD_SITE_LIST_FAILED,
    error,
  };
};

export const loadSiteList = (clientId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        const data = response.data.sort((a, b) => Collator.compare(a.name, b.name));
        dispatch(loadSiteListSuccess(data));
      })
      .catch((error) => {
        dispatch(loadSiteListFailed(error));
      });
  };
};

export const loadSiteSuccess = (data) => {
  return {
    type: LOAD_SITE_SUCCESS,
    data,
  };
};

export const loadSiteFailed = (error) => {
  return {
    type: LOAD_SITE_FAILED,
    error,
  };
};

export const loadSite = (clientId, siteId) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites/${siteId}?includeFields=client`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadSiteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadSiteFailed(error.response));
      });
  };
};

export const closeSiteForm = () => {
  return {
    type: CLOSE_SITE_FORM,
  };
};

export const editSite = () => {
  return {
    type: EDIT_SITE,
  };
};

export const updateSiteProperty = (data) => {
  return {
    type: UPDATE_SITE_PROPERTY,
    data,
  };
};

export const newSite = () => {
  return {
    type: NEW_SITE,
  };
};
export const createSiteSuccess = (data) => {
  notification['success']({
    message: i18next.t('site:control.notification.create_success_message.message', 'Successful!'),
    description: i18next.t('site:control.notification.create_success_message.description', 'New site has been created.'),
    className: 'sub2',
  });
  return {
    type: CREATE_SITE_SUCCESS,
    data,
  };
};

export const createSiteFailed = (error) => {
  notification['error']({
    message: i18next.t('site:control.notification.create_failed_message.message', 'Error!'),
    description: i18next.t('site:control.notification.create_failed_message.description', 'There is something wrong, please try again.'),
    className: 'sub2',
  });
  return {
    type: CREATE_SITE_FAILED,
    error,
  };
};

export const createSite = (clientId, data) => {
  return (dispatch) => {
    axios
      .post(`/clients/${clientId}/sites`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(createSiteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createSiteFailed(error));
      });
  };
};

export const saveSite = (id, clientId, data) => {
  return (dispatch) => {
    axios
      .patch(
        `/clients/${clientId}/sites/${id}`,
        { ...data },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      )
      .then((response) => {
        dispatch(saveSiteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveSiteFailed(error));
      });
  };
};

export const saveSiteSuccess = (data) => {
  notification['success']({
    message: i18next.t('site:control.notification.save_success_message.message', 'Successful!'),
    description: i18next.t('site:control.notification.save_success_message.description', 'The site has been edited.'),
    className: 'sub2',
  });
  return {
    type: SAVE_SITE_SUCCESS,
    data,
  };
};

export const saveSiteFailed = (error) => {
  notification['error']({
    message: i18next.t('site:control.notification.save_failed_message.message', 'Error!'),
    description: i18next.t('site:control.notification.save_failed_message.description', 'There is something wrong, please try again.'),
    className: 'sub2',
  });
  return {
    type: SAVE_SITE_FAILED,
    error,
  };
};

export const clearSelectedSite = () => {
  return {
    type: CLEAR_SELECTED_SITE,
  };
};

export const clearSiteList = () => {
  return {
    type: CLEAR_SITE_LIST,
  };
};

export const deleteSiteSuccess = (data) => {
  notification['success']({
    message: i18next.t('site:control.notification.delete_success_message.message'),
    description: i18next.t('site:control.notification.delete_success_message.description'),
    className: 'sub2',
  });
  return {
    type: DELETE_SITE_SUCCESS,
    data,
  };
};

export const deleteSiteFailed = (error) => {
  notification['error']({
    message: i18next.t('site:control.notification.delete_failed_message.message'),
    description: i18next.t('site:control.notification.delete_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: DELETE_SITE_FAILED,
    error,
  };
};

export const deleteSite = (clientId, siteId) => {
  return (dispatch) => {
    axios
      .delete(`/clients/${clientId}/sites/${siteId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(deleteSiteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteSiteFailed(error));
      });
  };
};
