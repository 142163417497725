import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { rgba } from 'polished';

const DayOfWeekCell = styled.div.attrs({ className: 'caption' })`
  color: ${(props) => props.gray_500};
`;

const DayOfWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 2px;
  ${DayOfWeekCell} {
    justify-self: center;
  }
  padding: 8px 12px;
`;

const CalendarCell = styled.div.attrs({ className: 'body2' })`
  color: ${(props) => {
    if (props.selected) {
      return props.theme.light;
    } else if (props.disabled || !props.isSameMonth) {
      return props.theme.gray_500;
    } else if (props.isToday) {
      return props.theme.secondary_400;
    } else if (props.isWeekend) {
      return props.theme.gray_700;
    }
    return props.theme.gray_900;
  }};
  text-decoration: ${(props) => {
    if (props.isToday) {
      return 'underline';
    }
    return 'none';
  }};

  background: ${(props) => props.selected && props.theme.secondary_400};

  &:hover {
    color: ${(props) => !props.disabled && props.isSameMonth && props.theme.gray_900};
    background: ${(props) => props.isSameMonth && 'rgba(0, 43, 213, 0.04)'};
  }

  &:active {
    background: ${(props) => props.isSameMonth && rgba(props.theme.primary_700, 0.24)};
  }

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
  }
`;

const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 2px;
  padding: 8px 12px;
`;

export default ({ id, value, pendingMonthYear, handleChangeValue, enablePastDate, disableBeforeDate }) => {
  const dayOfWeek = [0, 1, 2, 3, 4, 5, 6];

  const monthYearMoment = dayjs(pendingMonthYear, 'YYYY-MM').clone().startOf('month');
  const firstDayOfCalendar = monthYearMoment.clone().subtract(monthYearMoment.day(), 'days').startOf('day');
  const lastDayOfMonth = monthYearMoment.clone().endOf('month');
  const diffDay = lastDayOfMonth.diff(firstDayOfCalendar, 'days') + 1;
  const day = Array.apply(null, { length: diffDay }).map(Number.call, Number);
  const dayInCalendar = day.map((x) => dayjs(firstDayOfCalendar).add(x, 'days'));
  return (
    <React.Fragment>
      <DayOfWeek>
        {dayOfWeek.map((x) => (
          <DayOfWeekCell key={x}>{dayjs().day(x).format('dd')}</DayOfWeekCell>
        ))}
      </DayOfWeek>
      <Calendar>
        {dayInCalendar.map((x) => (
          <CalendarCell
            id={`date-${id}-${x.format('YYYY-MM-DD')}`}
            key={x.format('YYYY-MM-DD')}
            disabled={!enablePastDate && x.isBefore(disableBeforeDate, 'day')}
            isSameMonth={x.isSame(monthYearMoment, 'month')}
            isToday={x.isSame(dayjs(), 'day')}
            isWeekend={x.day() === 0 || x.day() === 6}
            onClick={() => {
              if (x.isSame(monthYearMoment, 'month') && (enablePastDate || !x.isBefore(disableBeforeDate, 'day'))) {
                handleChangeValue(x);
              }
            }}
            selected={x.isSame(value, 'day')}>
            {x.format('D')}
          </CalendarCell>
        ))}
      </Calendar>
    </React.Fragment>
  );
};
