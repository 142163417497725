import React, { Component } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import Button from '../general/Button';

import withIntl from '../../../hoc/withIntl';

const ModalOverlay = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

const ModalBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.light};
  width: ${(props) => (props.modalBoxStyle === ModalStyle.Minimum || props.modalBoxStyle === ModalStyle.Maximum ? 'auto' : '600px')};
  border-radius: 3px;
  z-index: 101;
  max-height: 90%;
  overflow-y: scroll;
`;

const ModalHeader = styled.div`
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  h6 {
    color: ${(props) => props.theme.primary};
    text-transform: capitalize;
    margin: 0;
  }
`;

const ModalDescriptionHeader = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const ModalFooter = styled.div`
  height: 80px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const ModalExtraFooter = styled.div`
  > *:not(:last-child) {
    display: block;
    margin-right: 16px;
  }
  float: left;
`;

const ModalMainFooter = styled.div`
  float: right;
`;

const SubmitButton = styled(Button).attrs({ colorTheme: 'primary' })`
  margin-left: 24px;
  @media only screen and (max-width: 375px) {
    margin-left: 0;
  }
`;

const CancelButton = styled(Button).attrs({ colorTheme: 'outlined' })`
  display: ${(props) => (props.hideCancelButton ? 'none' : '')};
`;

const ExtraFooterButton = styled(Button).attrs({ colorTheme: 'transparent' })``;

const HR = styled.hr`
  border: 0;
  height: 1px;
  background: ${(props) => props.theme.border_line};
  margin: 0 8px;
`;

const TitleHeader = styled.h6`
  margin: 0;
  color: ${(props) => props.theme.primary};
  text-transform: capitalize;
`;

const TitleDescriptionHeader = styled.div.attrs({ className: 'sub2' })`
  margin: 0;
  text-transform: capitalize;
`;

export const ModalStyle = {
  Normal: 'Normal',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
};

class Modal extends Component {
  render() {
    const { t, modalBoxStyle = ModalStyle.Normal } = this.props;

    if (this.props.visible) {
      return (
        <div>
          <ModalOverlay visible={this.props.visible} onClick={this.props.onCancel} />
          <ModalBox visible={this.props.visible} modalBoxStyle={this.props.modalBoxStyle} style={this.props.style}>
            {!this.props.hideHeader && (
              <>
                <ModalHeader>
                  <TitleHeader>{this.props.title}</TitleHeader>
                </ModalHeader>
                <ModalDescriptionHeader>
                  <TitleDescriptionHeader id='modal-description-header'>{this.props.description}</TitleDescriptionHeader>
                </ModalDescriptionHeader>
              </>
            )}
            {!this.props.hideHeader && (modalBoxStyle === ModalStyle.Normal || modalBoxStyle === ModalStyle.Maximum) && <HR />}
            {this.props.modals}
            {this.props.children}
            <ModalFooter>
              <ModalExtraFooter>
                {this.props.extraFooter &&
                  this.props.extraFooter.map((e) => {
                    return (
                      <ExtraFooterButton onClick={e.onClick} disabled={e.disabled}>
                        {e.text}
                      </ExtraFooterButton>
                    );
                  })}
              </ModalExtraFooter>
              <ModalMainFooter>
                <CancelButton
                  id={this.props.cancelButtonId || 'modal-cancel-button'}
                  onClick={this.props.onCancel}
                  hideCancelButton={this.props.hideCancelButton}>
                  {this.props.cancelText || t('ui:modal.cancel_button')}
                </CancelButton>
                <SubmitButton
                  id={this.props.okButtonId || 'modal-submit-button'}
                  onClick={this.props.onOk}
                  disabled={this.props.okDisabled || false}>
                  {this.props.okText || t('ui:modal.submit_button')}
                </SubmitButton>
              </ModalMainFooter>
            </ModalFooter>
          </ModalBox>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default compose(withIntl('ui'))(Modal);
