import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { HEADERSTATUS } from '../../../../enums/datepickerHeaderStatus';
import { LANGUAGE_TRANSLATION } from '../../../../enums/language';
import i18next from '../../../../i18n';

const BoardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  padding: 8px 12px;
`;

const BoardCard = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => (props.index === 0 || props.index === 11) && props.theme.gray_500};
  background-color: ${(props) => props.item === props.value && props.theme.gray_100};
  background-color: ${(props) => props.item === props.selected && props.theme.secondary_400};
  color: ${(props) => props.item === props.selected && props.theme.light};
  &:hover {
    background-color: ${(props) => props.theme.gray};
  }
`;

const YearPicker = (props) => {
  const [year, setYear] = useState([]);
  const pendingMonthYear = dayjs(props.pendingMonthYear).clone();

  const language = i18next.language;

  const getYears = (year) => {
    const years = Array.from({ length: 10 }, (value, index) => year.substring(0, 3) + index);
    const yearStart = +years[0] - 1 + ',';
    const yearEnd = ',' + (+years[9] + 1);

    return yearStart.concat(years, yearEnd).split(',');
  };

  useEffect(() => {
    if (!year.includes(pendingMonthYear.format('YYYY'))) {
      setYear(getYears(pendingMonthYear.format('YYYY')));
    }
  }, [pendingMonthYear, year]);

  return (
    <BoardBody>
      {year.map((item, index) => {
        const formattedYear = language === LANGUAGE_TRANSLATION.THAI ? parseInt(item) + 543 : item;
        return (
          <BoardCard
            id={`year-${props.id}-${item}`}
            key={index + props.id}
            onClick={() =>
              props.onPendingMonthYearValueChanged(dayjs(`${dayjs(props.pendingMonthYear).format('MMM')} ${item}`), HEADERSTATUS.YEAR)
            }
            selected={dayjs(props.monthYearSelected).format('YYYY')}
            value={dayjs(props.value).format('YYYY')}
            index={index}
            item={item}>
            {formattedYear}
          </BoardCard>
        );
      })}
    </BoardBody>
  );
};

export default YearPicker;
