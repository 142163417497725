import dayjs from './configuredDayJs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/th';

import localeTh from 'antd/es/date-picker/locale/th_TH';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { LANGUAGE_TRANSLATION } from '../enums/language';
import { MONTH_FULL_YEAR_FORMAT, DAY_MONTH_FULL_YEAR_FORMAT } from '../enums/date';
import i18next from '../i18n';

dayjs.extend(buddhistEra);
dayjs.extend(updateLocale);

export const getLocaleDatePicker = (language = LANGUAGE_TRANSLATION.THAI) => {
  switch (language) {
    case LANGUAGE_TRANSLATION.THAI:
      return localeTh;
    default:
      return localeEn;
  }
};

export const convertTimeFormat = (date) => {
  if (!date) return;
  const language = i18next.language;
  const dayjsDate = dayjs(date).locale(language);
  const hour = dayjsDate.hour();
  const minute = dayjsDate.minute();
  const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
  const formattedMinute = minute.toString().padStart(2, '0');
  const period = hour < 12 ? i18next.t('unit:am') : i18next.t('unit:pm');
  const formattedTime = `${formattedHour}:${formattedMinute} ${period}`;
  return formattedTime;
};

export const getCurrentLocaleMonthPicker = (language = LANGUAGE_TRANSLATION.THAI) => {
  switch (language) {
    case LANGUAGE_TRANSLATION.THAI:
      return dayjs(`${dayjs().format('MMM')} ${dayjs().format('BBBB')}`, MONTH_FULL_YEAR_FORMAT);
    default:
      return dayjs(`${dayjs().format('MMM')} ${dayjs().format('YYYY')}`, MONTH_FULL_YEAR_FORMAT);
  }
};

export const convertDateFormat = (date, customFormat) => {
  if (!date) return;
  const language = i18next.language;
  const dayjsDate = dayjs(date).locale(language);
  let dateFormat;

  if (language === LANGUAGE_TRANSLATION.THAI) {
    dateFormat = dayjsDate.format(customFormat ? customFormat.replace('YYYY', 'BBBB') : DAY_MONTH_FULL_YEAR_FORMAT.replace('YYYY', 'BBBB'));
  } else {
    dateFormat = dayjsDate.format(customFormat ? customFormat : DAY_MONTH_FULL_YEAR_FORMAT);
  }
  return dateFormat;
};
