import React from 'react';
import styled from 'styled-components';
import { Checkbox as CheckboxBase } from 'antd';

const WrapperCheckboxBase = styled.div.attrs({ className: 'body1' })``;

const Checkbox = (props) => {
  const { id, indeterminate, text, value, checked, onChange, ref, disabled } = props;
  return (
    <WrapperCheckboxBase>
      <CheckboxBase
        id={id}
        indeterminate={indeterminate}
        value={value || checked}
        onChange={onChange}
        checked={checked || value}
        disabled={disabled}
        ref={ref}>
        {text}
      </CheckboxBase>
    </WrapperCheckboxBase>
  );
};

export default Checkbox;
