export const MONTH_HALF_YEAR_FORMAT = 'MMM YY';

export const MONTH_FULL_YEAR_FORMAT = 'MMM YYYY';

export const MONTH_SHORTED_YEAR_FORMAT = 'MM/YYYY';

export const DAY_MONTH_FULL_YEAR_FORMAT = 'DD/MM/YYYY';

export const DAY_MONTH_SHORTED_YEAR_FORMAT = 'DD MMM YY';

export const YEAR_FORMAT = 'YYYY';

export const YEAR_FORMAT_IN_BA = 'BBBB';

export const DAY_MONTH_YEAR = 'dddd MMM YYYY';

export const EXCEL_DATE_FORMAT = 'YYYYMMDD';

export const YEAR_MONTH_DAY_HR_MIN = 'YYYY-MM-DD hh:mm';
