import React from 'react';
import styled from 'styled-components';
import TableContext from './TableContext';
import { PAGE_ENUM } from '../../../../enums/pageEnum';

const Wrapper = styled.tr.attrs({ className: 'sub1' })`
  color: ${(props) => props.theme.gray_700};
`;

const SortArrow = styled.i.attrs({ className: 'sub1' })`
  display: ${(props) => (props.children ? 'inline-block' : 'none')};
  font-family: 'Material Icons';
  font-style: normal;
`;

const HeaderColumn = styled.div`
  padding: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: ${(props) => {
    switch (props.align) {
      case 'right':
        return 'flex-end';
      case 'center':
        return 'center';
      case 'left':
        return 'flex-start';
      default:
        return 'flex-start';
    }
  }};
  user-select: none;

  ${SortArrow} {
    ${(props) => {
      switch (props.align) {
        case 'right':
          return 'margin-right: 10px;';
        case 'center':
          return 'margin-left: 10px;';
        case 'left':
          return 'margin-left: 10px;';
        default:
          return 'margin-left: 10px;';
      }
    }};
  }
`;

const TaskListHeaderColumn = styled.div`
  padding: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.primary};
  cursor: pointer;
  justify-content: ${(props) => {
    switch (props.align) {
      case 'right':
        return 'flex-end';
      case 'center':
        return 'center';
      case 'left':
        return 'flex-start';
      default:
        return 'flex-start';
    }
  }};
  user-select: none;

  ${SortArrow} {
    ${(props) => {
      switch (props.align) {
        case 'right':
          return 'margin-right: 10px;';
        case 'center':
          return 'margin-left: 10px;';
        case 'left':
          return 'margin-left: 10px;';
        default:
          return 'margin-left: 10px;';
      }
    }};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  width: 28px;
`;

const TexetWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;

const Header = (props) => {
  const { page, column, sortData, sorted, reversed } = props;
  const HeaderStyle = () => {
    const headerDisplay = (
      <HeaderWrapper>
        {column.align === 'right' && column.sorter && (
          <IconWrapper>
            <SortArrow>
              {sorted === column.headerTitle && 'arrow_upward'}
              {reversed === column.headerTitle && 'arrow_downward'}
            </SortArrow>
          </IconWrapper>
        )}
        <TexetWrapper>{column.headerRender ? column.headerRender(column.headerTitle) : column.headerTitle}</TexetWrapper>
        {column.align !== 'right' && column.sorter && (
          <IconWrapper>
            <SortArrow>
              {sorted === column.headerTitle && 'arrow_upward'}
              {reversed === column.headerTitle && 'arrow_downward'}
            </SortArrow>
          </IconWrapper>
        )}
      </HeaderWrapper>
    );
    switch (page) {
      case PAGE_ENUM.TASK_LIST:
        return (
          <TaskListHeaderColumn {...column} onClick={() => sortData(column.headerTitle, column.sorter)}>
            {headerDisplay}
          </TaskListHeaderColumn>
        );

      default:
        return (
          <HeaderColumn {...column} onClick={() => sortData(column.headerTitle, column.sorter)}>
            {headerDisplay}
          </HeaderColumn>
        );
    }
  };
  return <HeaderStyle/>
};
const TableHeader = ({ columns, onPage }) => {
  const theme = {
    primary: '#153760',
  };
  return (
    <Wrapper>
      <TableContext.Consumer>
        {({ sortData, sorted, reversed, actionButton }) => (
          <React.Fragment>
            {columns.map((column, idx) => (
              <th key={idx} width={column.width}>
                <Header page={onPage} column={column} sortData={sortData} sorted={sorted} reversed={reversed} />
              </th>
            ))}
            {onPage === PAGE_ENUM.TASK_LIST ? <th style={{ backgroundColor: theme.primary }}></th> : null}
            {actionButton && <th></th>}
          </React.Fragment>
        )}
      </TableContext.Consumer>
    </Wrapper>
  );
};

export default TableHeader;
