import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './redux/auth';
import organizationReducer from './redux/organization';
import unitReducer from './redux/unit';
import userReducer from './redux/user';
import chemicalReducer from './redux/chemical';
import clientReducer from './redux/client';
import siteReducer from './redux/site';
import plotReducer from './redux/plot';
import obstacleReducer from './redux/obstacle';
import taskReducer from './redux/task';
import registrationReducer from './redux/registration';
import organizationsettingReducer from './redux/organizationSetting';
import policyReducer from './redux/policy';
import promptReducer from './redux/prompt';
import vehicleModelReducer from './redux/vehicleModel';
import referenceDataReducer from './redux/referenceData';
import plantReducer from './redux/plant';
import varietyReducer from './redux/variety';
import fertilizerReducer from './redux/fertilizer';

const createRootReducer = (asyncReducers) => {
  return combineReducers({
    auth: authReducer,
    organization: organizationReducer,
    unit: unitReducer,
    user: userReducer,
    chemical: chemicalReducer,
    client: clientReducer,
    site: siteReducer,
    plot: plotReducer,
    obstacle: obstacleReducer,
    task: taskReducer,
    registration: registrationReducer,
    organizationsetting: organizationsettingReducer,
    policy: policyReducer,
    prompt: promptReducer,
    vehicleModel: vehicleModelReducer,
    referenceData: referenceDataReducer,
    plant: plantReducer,
    variety: varietyReducer,
    fertilizer: fertilizerReducer,
    ...asyncReducers,
  });
};

const initStore = () => {
  const rootReducer = createRootReducer();

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

  store.asyncReducers = {};
  store.injectReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createRootReducer(store.asyncReducers));
    return store;
  };

  return store;
};

export default initStore;
