import React from 'react';
import styled from 'styled-components';
import TableContext from './TableContext';
import { get } from 'lodash';
import PopoverModal from '../../general/PopoverModal';

const Wrapper = styled.tr.attrs({ className: 'sub2' })`
  color: ${(props) => props.theme.gray_700};
  padding: 16px 0px;
  min-height: 74px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};

  &:hover {
    background: rgba(27, 64, 109, 0.04);
  }
`;

const DefaultCell = styled.div`
  color: ${(props) => props.theme.gray_700};
  text-align: ${(props) => props.align};
  padding: 16px 8px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: ${(props) => {
    switch (props.align) {
      case 'right':
        return 'flex-end';
      case 'center':
        return 'center';
      case 'left':
        return 'flex-start';
      default:
        return 'flex-start';
    }
  }};
`;

const fetchData = (data, key) => {
  if (typeof data === undefined) {
    return;
  }
  const index = key.indexOf('.');
  if (index > -1) {
    return fetchData(data[key.substring(0, index)], key.substr(index + 1));
  }
  return get(data, key);
};

const TableRow = ({ data, index, columns, onClick }) => {
  return (
    <Wrapper>
      {columns.map((column, idx) => (
        <td key={idx} onClick={onClick} style={{ cursor: 'pointer' }}>
          <DefaultCell {...column}>
            {column.render ? column.render(fetchData(data, column.dataIndex), data) : get(data, column.dataIndex)}
          </DefaultCell>
        </td>
      ))}
      <TableContext.Consumer>
        {({ actionMenu, handleActionMenu }) => {
          if (actionMenu && handleActionMenu)
            return (
              <td style={{ cursor: 'pointer' }}>
                <PopoverModal
                  visible={data.isPopoverVisible}
                  onVisibleChange={(visible) => {
                    handleActionMenu(index, visible);
                  }}
                  content={actionMenu(data)}
                />
              </td>
            );
        }}
      </TableContext.Consumer>
    </Wrapper>
  );
};

export default TableRow;
