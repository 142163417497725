import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';
import Collator from '../collator';

// Actions
export const LOAD_CLIENT_LIST_SUCCESS = 'LOAD_CLIENT_LIST_SUCCESS';
export const LOAD_CLIENT_LIST_FAILED = 'LOAD_CLIENT_LIST_FAILED';

export const LOAD_CLIENT = 'LOAD_CLIENT';
export const LOAD_CLIENT_SUCCESS = 'LOAD_CLIENT_SUCCESS';
export const LOAD_CLIENT_FAILED = 'LOAD_CLIENT_FAILED';

export const UPDATE_CLIENT_PROPERTY = 'UPDATE_CLIENT_PROPERTY';
export const CLOSE_CLIENT_FORM = 'CLOSE_CLIENT_FORM';

export const NEW_CLIENT = 'NEW_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILED = 'CREATE_CLIENT_FAILED';

export const EDIT_CLIENT = 'EDIT_CLIENT';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_FAILED = 'SAVE_CLIENT_FAILED';
export const CLEAR_SELECTED_CLIENT = 'CLEAR_SELECTED_CLIENT';

export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILED = 'DELETE_CLIENT_FAILED';

const initialState = {
  all: [],
  selected: undefined,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NEW_CLIENT:
      return {
        ...state,
        form: {
          isNew: true,
        },
      };
    case LOAD_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_CLIENT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_CLIENT_SUCCESS:
      return {
        ...state,
        selected: action.data,
      };
    case LOAD_CLIENT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_CLIENT:
      return {
        ...state,
        form: {
          ...state.selected,
        },
      };
    case CLOSE_CLIENT_FORM:
      return {
        ...state,
        form: undefined,
      };
    case UPDATE_CLIENT_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case SAVE_CLIENT_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        selected: {
          ...state.selected,
          ...action.data,
        },
        form: undefined,
      };
    case SAVE_CLIENT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_SELECTED_CLIENT:
      return {
        ...state,
        selected: undefined,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        form: undefined,
      };
    case CREATE_CLIENT_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        all: state.all.filter((state) => state._id !== action.data._id),
      };
    case DELETE_CLIENT_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export const loadClientListSuccess = (data) => {
  return {
    type: LOAD_CLIENT_LIST_SUCCESS,
    data,
  };
};

export const loadClientListFailed = (error) => {
  return {
    type: LOAD_CLIENT_LIST_FAILED,
    error,
  };
};

export const loadClientList = (fields = []) => {
  const queryString = fields.length > 0 ? `includeFields=${fields.join(',')}` : '';

  return (dispatch) => {
    axios
      .get(`/clients?${queryString}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        const data = response.data.sort((a, b) => Collator.compare(a.name, b.name));
        dispatch(loadClientListSuccess(data));
      })
      .catch((error) => {
        dispatch(loadClientListFailed(error));
      });
  };
};

export const loadClientSuccess = (data) => {
  return {
    type: LOAD_CLIENT_SUCCESS,
    data,
  };
};

export const loadClientFailed = (error) => {
  return {
    type: LOAD_CLIENT_FAILED,
    error,
  };
};

export const deleteClientSuccess = (data) => {
  notification['success']({
    message: i18next.t('client:control.notification.delete_success_message.message', 'Successful!'),
    description: i18next.t('client:control.notification.delete_success_message.description', 'New client has been added.'),
    className: 'sub2',
  });
  return {
    type: DELETE_CLIENT_SUCCESS,
    data,
  };
};

export const deleteClientFailed = (error) => {
  notification['error']({
    message: i18next.t('client:control.notification.delete_failed_message.message'),
    description: i18next.t('client:control.notification.delete_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: DELETE_CLIENT_FAILED,
    error,
  };
};

export const loadClient = (id) => {
  return (dispatch) => {
    axios
      .get(`/clients/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadClientSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadClientFailed(error));
      });
  };
};

export const newClient = () => {
  return {
    type: NEW_CLIENT,
  };
};

export const editClient = () => {
  return {
    type: EDIT_CLIENT,
  };
};

export const closeClientForm = () => {
  return {
    type: CLOSE_CLIENT_FORM,
  };
};

export const updateClientProperty = (data) => {
  return {
    type: UPDATE_CLIENT_PROPERTY,
    data,
  };
};

export const saveClientSuccess = (data) => {
  notification['success']({
    message: i18next.t('client:control.notification.save_success_message.message'),
    description: i18next.t('client:control.notification.save_success_message.description'),
    className: 'sub2',
  });
  return {
    type: SAVE_CLIENT_SUCCESS,
    data,
  };
};

export const saveClientFailed = (error) => {
  notification['error']({
    message: i18next.t('client:control.notification.save_failed_message.message'),
    description: i18next.t('client:control.notification.save_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: SAVE_CLIENT_FAILED,
    error,
  };
};

export const saveClient = (id, data) => {
  return (dispatch) => {
    axios
      .patch(`/clients/${id}`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(saveClientSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveClientFailed(error));
      });
  };
};

export const clearSelectedClient = () => {
  return {
    type: CLEAR_SELECTED_CLIENT,
  };
};

export const createClientSuccess = (data) => {
  notification['success']({
    message: i18next.t('client:control.notification.create_success_message.message', 'Successful!'),
    description: i18next.t('client:control.notification.create_success_message.description', 'New client has been added.'),
    className: 'sub2',
  });
  return {
    type: CREATE_CLIENT_SUCCESS,
    data,
  };
};

export const createClientFailed = (error) => {
  notification['error']({
    message: i18next.t('client:control.notification.create_failed_message.message'),
    description: i18next.t('client:control.notification.create_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_CLIENT_FAILED,
    error,
  };
};

export const createClient = (data) => {
  return (dispatch) => {
    axios
      .post('/clients', { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(createClientSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createClientFailed(error));
      });
  };
};

export const deleteClient = (id) => {
  return (dispatch) => {
    axios
      .delete(`/clients/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(deleteClientSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteClientFailed(error));
      });
  };
};
