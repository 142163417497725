import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';
import { STATUS_ENUM } from '../enums/statusEnum';

// Actions
export const LOAD_GENERATION_VARIETY_LIST_SUCCESS = 'LOAD_GENERATION_VARIETY_LIST_SUCCESS';
export const LOAD_GENERATION_VARIETY_LIST_FAILED = 'LOAD_GENERATION_VARIETY_LIST_FAILED';

export const LOAD_VARIETY_LIST_SUCCESS = 'LOAD_VARIETY_LIST_SUCCESS';
export const LOAD_VARIETY_LIST_FAILED = 'LOAD_VARIETY_LIST_FAILED';
export const VALIDATE_VARIETY_CODE_SUCCESS = 'VALIDATE_VARIETY_CODE_SUCCESS';
export const VALIDATE_VARIETY_CODE_FAILED = 'VALIDATE_VARIETY_CODE_FAILED';

export const UPDATE_VARIETY_SUCCESS = 'UPDATE_VARIETY_SUCCESS';
export const UPDATE_VARIETY_FAILED = 'UPDATE_VARIETY_FAILED';

export const CREATE_VARIETY_SUCCESS = 'CREATE_VARIETY_SUCCESS';
export const CREATE_VARIETY_FAILED = 'CREATE_VARIETY_FAILED';

export const VALIDATE_VARIETY_GENERATION_CODE_SUCCESS = 'VALIDATE_VARIETY_GENERATION_CODE_SUCCESS';
export const VALIDATE_VARIETY_GENERATION_CODE_FAILED = 'VALIDATE_VARIETY_GENERATION_CODE_FAILED';

export const CREATE_VARIETY_GENERATION_SUCCESS = 'CREATE_VARIETY_GENERATION_SUCCESS';
export const CREATE_VARIETY_GENERATION_FAILED = 'CREATE_VARIETY_GENERATION_FAILED';

export const UPDATE_VARIETY_GENERATION_SUCCESS = 'UPDATE_VARIETY_GENERATION_SUCCESS';
export const UPDATE_VARIETY_GENERATION_FAILED = 'UPDATE_VARIETY_GENERATION_FAILED';

// Reducer

const initialState = {
  all: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_VARIETY_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_VARIETY_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case VALIDATE_VARIETY_CODE_SUCCESS:
      return {
        ...state,
        isVarietyCodeValid: Boolean(...action.data),
      };
    case VALIDATE_VARIETY_CODE_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case UPDATE_VARIETY_SUCCESS:
      return {
        ...state,
        ...action.data,
        all: state.all.map((item) => {
          if (item._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return item;
        }),
      };

    case UPDATE_VARIETY_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CREATE_VARIETY_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
      };
    case CREATE_VARIETY_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case LOAD_GENERATION_VARIETY_LIST_SUCCESS:
      return {
        ...state,
        generation: action.data,
      };
    case LOAD_GENERATION_VARIETY_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case VALIDATE_VARIETY_GENERATION_CODE_SUCCESS:
      return {
        ...state,
        isVarietyGenerationCodeValid: action.data,
      };
    case VALIDATE_VARIETY_GENERATION_CODE_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CREATE_VARIETY_GENERATION_SUCCESS:
      return {
        ...state,
        generation: [...state.generation, action.data],
      };

    case CREATE_VARIETY_GENERATION_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case UPDATE_VARIETY_GENERATION_SUCCESS:
      return {
        ...state,
        ...action.data,
        generation: state.generation.map((item) => {
          if (item._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return item;
        }),
      };

    case UPDATE_VARIETY_GENERATION_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

// Action Creators

export const createVarietySuccess = (data) => {
  notification['success']({
    message: i18next.t('variety:control.notification.variety.create_success_message.message'),
    description: i18next.t('variety:control.notification.variety.create_success_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_VARIETY_SUCCESS,
    data,
  };
};

export const createVarietyFailed = (data) => {
  notification['error']({
    message: i18next.t('variety:control.notification.variety.create_failed_message.message'),
    description: i18next.t('variety:control.notification.variety.create_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_VARIETY_FAILED,
    data,
  };
};

export const loadVarietyListSuccess = (data) => {
  return {
    type: LOAD_VARIETY_LIST_SUCCESS,
    data,
  };
};

export const loadVarietyListFailed = (error) => {
  return {
    type: LOAD_VARIETY_LIST_FAILED,
    error,
  };
};

export const validateVarietyCodeSuccess = (data) => {
  return {
    type: VALIDATE_VARIETY_CODE_SUCCESS,
    data,
  };
};

export const validateVarietyCodeFailed = (data) => {
  return {
    type: VALIDATE_VARIETY_CODE_FAILED,
    data,
  };
};

export const loadGenerationVarietyListSuccess = (data) => {
  return {
    type: LOAD_GENERATION_VARIETY_LIST_SUCCESS,
    data,
  };
};

export const loadGenerationVarietyListFailed = (error) => {
  return {
    type: LOAD_GENERATION_VARIETY_LIST_FAILED,
    error,
  };
};

export const validateVarietyGenerationCodeSuccess = (data) => {
  return {
    type: VALIDATE_VARIETY_GENERATION_CODE_SUCCESS,
    data,
  };
};

export const validateGenerationVarietyCodeFailed = (data) => {
  return {
    type: VALIDATE_VARIETY_GENERATION_CODE_FAILED,
    data,
  };
};

export const updateVarietySuccess = (data) => {
  notification['success']({
    message: i18next.t('variety:control.notification.variety.update_success_message.message'),
    description: i18next.t('variety:control.notification.variety.update_success_message.description'),
    className: 'sub2',
  });
  return {
    type: UPDATE_VARIETY_SUCCESS,
    data,
  };
};

export const updateVarietyFailed = (data) => {
  notification['error']({
    message: i18next.t('variety:control.notification.variety.update_failed_message.message'),
    description: i18next.t('variety:control.notification.variety.update_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: UPDATE_VARIETY_FAILED,
    data,
  };
};

export const createVarietyGenerationSuccess = (data) => {
  notification['success']({
    message: i18next.t('variety:control.notification.variety_generation.create_success_message.message'),
    description: i18next.t('variety:control.notification.variety_generation.create_success_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_VARIETY_GENERATION_SUCCESS,
    data,
  };
};

export const createVarietyGenerationFailed = (data) => {
  notification['error']({
    message: i18next.t('variety:control.notification.variety_generation.create_failed_message.message'),
    description: i18next.t('variety:control.notification.variety_generation.create_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: VALIDATE_VARIETY_GENERATION_CODE_FAILED,
    data,
  };
};

export const updateVarietyGenerationSuccess = (data) => {
  notification['success']({
    message: i18next.t('variety:control.notification.variety_generation.update_success_message.message'),
    description: i18next.t('variety:control.notification.variety_generation.update_success_message.description'),
    className: 'sub2',
  });
  return {
    type: UPDATE_VARIETY_GENERATION_SUCCESS,
    data,
  };
};

export const updateVarietyGenerationFailed = (data) => {
  notification['error']({
    message: i18next.t('variety:control.notification.variety_generation.update_failed_message.message'),
    description: i18next.t('variety:control.notification.variety_generation.update_failed_message.description'),
    className: 'sub2',
  });
  return {
    type: UPDATE_VARIETY_GENERATION_FAILED,
    data,
  };
};

export const createVariety = (plantId, varietyCode, varietyName) => {
  return (dispatch) => {
    const requestBody = {
      commonName: varietyName,
      code: varietyCode,
      plantId,
      translation: {
        en: { commonName: varietyName },
        th: { commonName: varietyName },
      },
      status: STATUS_ENUM.ACTIVE,
    };
    axios
      .post(`/varieties`, requestBody, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(createVarietySuccess(response.data));
      })
      .catch((error) => {
        dispatch(createVarietyFailed(error));
      });
  };
};

export const loadVarietyList = () => {
  return (dispatch) => {
    axios
      .get('/varieties?includeFields=plant&$sort[code]=1', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadVarietyListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadVarietyListFailed(error));
      });
  };
};

export const validateVarietyCode = (plantId, varietyCode) => {
  return (dispatch) => {
    axios
      .get(`/varieties?plantId=${plantId}&code=${varietyCode}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(validateVarietyCodeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(validateVarietyCodeFailed(error));
      });
  };
};

export const updateVariety = (plantId, varietyCode, varietyName, varietyId, status) => {
  return (dispatch) => {
    const requestBody = {
      commonName: varietyName,
      code: varietyCode,
      plantId,
      translation: {
        en: { commonName: varietyName },
        th: { commonName: varietyName },
      },
      status,
    };
    axios
      .patch(`/varieties/${varietyId}?includeFields=plant`, requestBody, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(updateVarietySuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateVarietyFailed(error));
      });
  };
};

export const loadGenerationVarietyList = () => {
  return (dispatch) => {
    axios
      .get(`/variety-generations?includeFields=plant,variety&$sort[code]=1`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadGenerationVarietyListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadGenerationVarietyListFailed(error));
      });
  };
};

export const validateVarietyGenerationCode = (varietyGenerationCode) => {
  return (dispatch) => {
    axios
      .get(`/variety-generations?code=${varietyGenerationCode}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(validateVarietyGenerationCodeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(validateGenerationVarietyCodeFailed(error));
      });
  };
};

export const createVarietyGeneration = (data) => {
  return (dispatch) => {
    axios
      .post(`/variety-generations`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(createVarietyGenerationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createVarietyGenerationFailed(error));
      });
  };
};

export const updateVarietyGeneration = (data, id) => {
  return (dispatch) => {
    axios
      .patch(`/variety-generations/${id}?includeFields=plant,variety`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(updateVarietyGenerationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateVarietyGenerationFailed(error));
      });
  };
};
