import React from 'react';
import styled from 'styled-components';
import { Popover as NativePopover } from 'antd';
import PropTypes from 'prop-types';

const MaterialIcon = styled.i.attrs({ className: 'material-icons' })`
  position: relative;
  display: inline;
  font-size: 24px;
  line-height: 16px;
  top: 3px;
  color: ${(props) => props.theme.gray_700};
`;

const Popover = styled(NativePopover)`
  cursor: pointer;
`;

const PopoverModal = ({ placement, visible, onVisibleChange, content, icon }) => {
  return (
    <Popover placement={placement} trigger='click' visible={visible} onVisibleChange={onVisibleChange} content={content}>
      <MaterialIcon id='button-icon'>{icon}</MaterialIcon>
    </Popover>
  );
};

PopoverModal.propTypes = {
  placement: PropTypes.string,
  visible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  content: PropTypes.element.isRequired,
  icon: PropTypes.string,
};

PopoverModal.defaultProps = {
  placement: 'bottomRight',
  visible: false,
  onVisibleChange: () => {},
  icon: 'more_vert',
};

export default PopoverModal;
